const TITLE_POSITIONS = {
  TOP: "top",
  BOTTOM: "bottom",
};

export const POSITION_APPEARANCE_MAPPING = {
  [TITLE_POSITIONS.TOP]: "default",
  [TITLE_POSITIONS.BOTTOM]: "accent",
  default: "default",
};
