import { Poster } from "shared/components/Poster/Poster";

import { POSITION_APPEARANCE_MAPPING } from "./Content003.config";

export const Content003 = ({ payload }) => {
  const title = payload?.title ?? "";
  const image = payload?.media ?? {};
  const appearance =
    POSITION_APPEARANCE_MAPPING[payload?.titlePosition] ??
    POSITION_APPEARANCE_MAPPING.default;

  return <Poster image={image} title={title} appearance={appearance} />;
};

export default Content003;
